import React, { FC, ReactNode } from 'react';

export type TSectionProps = {
  children: ReactNode;
  header: ReactNode;
};

export const Section: FC<TSectionProps> = ({ children, header }) => (
  <div className="w-full flex flex-col gap-7">
    {header}
    {children}
  </div>
);
