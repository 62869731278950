import React, { FC } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import InformationIcon from '../Icons/InformationIcon';
import { colors } from '../Spinner/utils/colors';
import { TToolTipDirection } from '../Tooltip/types';

type TSectionTitleTooltipProps = {
  text: string;
  direction?: TToolTipDirection;
};

export const SectionTitleTooltip: FC<TSectionTitleTooltipProps> = ({ text, direction = 'bottom' }) => (
  <Tooltip
    direction={direction}
    trigger={
      <div className="w-[20px] h-[20px]">
        <InformationIcon color={colors?.gray[400]} />
      </div>
    }
  >
    {text}
  </Tooltip>
);
