import React, { FC, RefObject } from 'react';
import Button from '../Button';
import Spinner from '../Spinner/Spinner';
import { useScrollToSection } from './hooks/useScrollToSection';

type TLoadMoreButtonProps = {
  onClick: () => void;
  hasMoreToLoad?: boolean;
  isLoading?: boolean;
  isCollapsedSection?: boolean;
  sectionTitleRef: RefObject<HTMLElement>;
};

export const LoadMoreButton: FC<TLoadMoreButtonProps> = ({
  onClick,
  hasMoreToLoad,
  isCollapsedSection,
  isLoading,
  sectionTitleRef,
}) => {
  const shouldShowLoadLess = !isCollapsedSection && !hasMoreToLoad;
  const title = shouldShowLoadLess ? 'Load Less' : 'Load More';

  const scrollToSection = useScrollToSection(sectionTitleRef);

  const onClickWithScrollToSection = () => {
    onClick();
    if (shouldShowLoadLess) {
      scrollToSection();
    }
  };

  return (
    <Button
      variant="ghost"
      onClick={onClickWithScrollToSection}
      className="!px-4 !py-2 !text-sm text-t2-neutral-grey08"
      outlined
      rounded="2xl"
    >
      <div className="flex min-w-20 gap-2 justify-center items-center">
        {isLoading ? (
          <div className="h-4 my-[2px]">
            <Spinner color="gray" size={10} />
          </div>
        ) : (
          title
        )}
      </div>
    </Button>
  );
};
