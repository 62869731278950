import { useCallback, RefObject } from 'react';

export const useScrollToSection = (ref: RefObject<HTMLElement>) =>
  useCallback(() => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - 150;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [ref]);
