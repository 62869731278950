import { FC } from 'react';
import Typo from '../Typography';

type TNoSearchResultsProps = {
  title: string;
  description?: string;
};

export const NoSearchResults: FC<TNoSearchResultsProps> = ({ title, description }) => (
  <section className="flex flex-col items-center mt-2">
    <Typo className="">{title}</Typo>
    {description && <Typo className="text-t2-neutral-grey05">{description}</Typo>}
  </section>
);
