import React, { FC } from 'react';
import { Territory } from 't2-graphql-types';
import { TerritoryDetails } from './TerritoryDetails';
import { TerritoryDetailsModalContextProvider } from '../TerritoryDetails/context/TerritoryDetailsModalContextProvider';

type TTerritoryDiscoveryResultsProps = {
  results?: Territory[];
};

export const TerritoryDiscoveryResults: FC<TTerritoryDiscoveryResultsProps> = ({ results }) => (
  <TerritoryDetailsModalContextProvider>
    <section className="w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-4 xl:grid-cols-3 gap-6">
      {results?.map((territory) => (
        <TerritoryDetails territory={territory} key={territory.id} />
      ))}
    </section>
  </TerritoryDetailsModalContextProvider>
);
