import { useT2Query } from '../../../hooks/useT2Query';
import { searchTerritoriesQuery } from '../queries/searchTerritoriesQuery';
import { TUseFetchTerritoryDiscoveryDataProps, TUseFetchTerritoryDiscoveryDataReturn } from '../types';

export const useFetchTerritoryDiscoveryData = ({
  searchTerm,
  sortBy,
  take,
  initialTake = take,
  placement,
}: TUseFetchTerritoryDiscoveryDataProps): TUseFetchTerritoryDiscoveryDataReturn => {
  const { data, loading, fetchMore, called } = useT2Query(searchTerritoriesQuery, {
    variables: {
      search: searchTerm || '',
      skip: 0,
      take: initialTake,
      infiniteScroll: true,
      ...(sortBy ? { sortBy } : {}),
      placement,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { results, resultsCount } = data?.searchTerritories || {};

  return {
    loading,
    results,
    fetchMore,
    resultsCount,
    called,
  };
};
