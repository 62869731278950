import React, { FC, ReactNode } from 'react';
import Typo from '../Typography';
import { SectionTitleTooltip } from './SectionTitleTooltip';

export type TSectionHeaderProps = {
  title?: string;
  tooltipText?: string;
  leftSide?: ReactNode;
  rightSide?: ReactNode;
};

export const SectionHeader: FC<TSectionHeaderProps> = ({ title, tooltipText, leftSide, rightSide }) => (
  <div className="flex flex-col sm:flex-row justify-between items-center gap-1">
    <div className="flex items-center gap-2">
      {title && (
        <Typo variant="p1" className="!text-[22px] lg:!text-[28px] text-t2-neutral-grey09 font-semibold">
          {title}
        </Typo>
      )}
      {tooltipText && <SectionTitleTooltip text={tooltipText} />}
      <div className="flex items-center gap-2">{leftSide}</div>
    </div>
    <div className="flex items-center gap-2">{rightSide}</div>
  </div>
);
