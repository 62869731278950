import { gql } from '@apollo/client';

export const searchTerritoriesQuery = gql`
  query searchTerritories($search: String!, $where: TerritoryWhereInput, $skip: Int!, $take: Int!, $sortBy: String) {
    searchTerritories(search: $search, where: $where, skip: $skip, take: $take, sortBy: $sortBy) {
      results {
        id
        name
        slug
        version
        createdAt
        logo {
          ...BaseImageFields
        }
        description
        articlesCount(where: { status: { equals: "published" }, type: { equals: ARTICLE } })
        totalTimePoints
        membershipsCount(where: { status: { equals: ACTIVE } })
        memberships(take: 4, where: { status: { equals: ACTIVE } }) {
          id
          user {
            id
            name
            publicAddress
            username
            cumulativeTimePoints
            profileImage {
              ...BaseImageFields
            }
          }
        }
      }
      resultsCount
    }
  }
`;
