import { TSortingDropdownItems } from '../SortingDropdown/types';

export const TERRITORY_DISCOVERY_RESULTS_PAGE_SIZE = 9;

export enum TerritorySortBy {
  Newest = 'Newest',
  Oldest = 'Oldest',
  MostTimePoints = 'MostTimePoints',
}

export const TERRITORY_DISCOVERY_SORTING_DROPDOWN_ITEMS: TSortingDropdownItems[] = [
  {
    name: TerritorySortBy.MostTimePoints,
    label: 'Most Time Points',
  },
  {
    name: TerritorySortBy.Newest,
    label: 'Newest',
  },
  {
    name: TerritorySortBy.Oldest,
    label: 'Oldest',
  },
];
