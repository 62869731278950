import React, { FC } from 'react';
import Button from '../Button';
import ProtectWithLogin from '../ProtectWithLogin/ProtectWithLogin';
import { useCreateTerritory } from '../Territory/hooks/useCreateTerritory';
import { CreateTerritory } from '../Territory/CreateTerritory';

type TNewTerritoryButtonProps = {
  onClick?: () => void;
  isAuthenticated: boolean;
};

export const NewTerritoryButton: FC<TNewTerritoryButtonProps> = ({ isAuthenticated }) => {
  const createTerritory = useCreateTerritory({ isUserAuthenticated: isAuthenticated });

  return (
    <>
      <ProtectWithLogin>
        <Button
          variant="primary"
          rounded
          onClick={createTerritory.openCreateTerritoryModalWithAnalytics}
          className="flex justify-center px-4 py-2 md:py-[10px]"
        >
          <span className="flex items-center before:content-plus before:h-[20px] before:mr-2 whitespace-nowrap text-sm">
            New Territory
          </span>
        </Button>
      </ProtectWithLogin>
      <CreateTerritory {...createTerritory} />
    </>
  );
};
