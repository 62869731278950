import { FC } from 'react';
import { Placeholder } from '../Placeholder/Placeholder';

type TTerritoryDiscoveryResultsPlaceholderProps = {
  numberOfElements: number;
};

export const TerritoryDiscoveryResultsPlaceholder: FC<TTerritoryDiscoveryResultsPlaceholderProps> = ({
  numberOfElements,
}) => (
  <section className="w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-4 xl:grid-cols-3 gap-6">
    {new Array(numberOfElements).fill(undefined).map((value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Placeholder key={index} className="h-[483px]" />
    ))}
  </section>
);
