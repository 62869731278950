import { TUseSectionListResultsProps, TUseSectionListResultsReturn } from '../types';

export const useSectionListResults = <Results extends object[]>({
  loading,
  results,
  fetchMore,
  take,
  resultsCount,
  called,
}: TUseSectionListResultsProps<Results>): TUseSectionListResultsReturn<Results> => {
  const hasMoreToLoad = results?.length !== resultsCount;

  const fetchMoreData = async () => {
    if (!hasMoreToLoad || loading || !results.length) {
      return;
    }

    await fetchMore({
      variables: {
        skip: results.length,
        take,
      },
    });
  };

  const noResults = !loading && called && resultsCount === 0;
  const firstResultsLoading = loading && !results?.length;
  const hasMore = resultsCount > take;

  return {
    results,
    fetchMore: fetchMoreData,
    loading,
    hasMoreToLoad,
    resultsCount,
    noResults,
    firstResultsLoading,
    hasMore,
  };
};
