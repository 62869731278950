import React, { FC } from 'react';
import Button from '../Button';
import { ShieldIcon } from '../Icons/ShieldIcon';
import { getTerritoryChooseRolePageUrl } from '../../services/pagesUrl/getTerritoryChooseRolePageUrl';
import LinkButton from '../LinkButton/LinkButton';
import ProtectWithLogin from '../ProtectWithLogin/ProtectWithLogin';

type TTerritoryMembershipButtonProps = {
  isTerritoryMember: boolean;
  isTerritoryAdmin: boolean;
  canUpdateTerritoryGlobally: boolean;
  canUpdateTerritory: boolean;
  loading: boolean;
  territorySlug: string;
};

export const TerritoryMembershipButton: FC<TTerritoryMembershipButtonProps> = ({
  isTerritoryMember,
  isTerritoryAdmin,
  canUpdateTerritoryGlobally,
  canUpdateTerritory,
  loading,
  territorySlug,
}) => {
  const isMember = isTerritoryAdmin || canUpdateTerritoryGlobally || canUpdateTerritory || isTerritoryMember;
  const memberLabel = (
    <div className="flex gap-2">
      <span>Member</span>
      <ShieldIcon className="!w-5 !h-5 text-purple-400" />
    </div>
  );

  const memberCta = (
    <Button
      variant="ghost"
      className="!w-full px-2 py-4 justify-center !text-sm !text-t2-neutral-grey09 h-10 !rounded-[10px] !border !border-t2-neutral-grey03 shadow-none hover:shadow-none"
      clickable={false}
    >
      {!loading && memberLabel}
    </Button>
  );

  const joinTerritoryCta = (
    <ProtectWithLogin>
      <LinkButton
        variant="ghost"
        className="!w-full px-2 py-4 justify-center !text-sm !text-t2-neutral-grey09 h-10 !rounded-[10px] !border !border-t2-neutral-grey03 !shadow-none"
        href={getTerritoryChooseRolePageUrl(territorySlug)}
      >
        {!loading && 'Become a member'}
      </LinkButton>
    </ProtectWithLogin>
  );

  return isMember ? memberCta : joinTerritoryCta;
};
