import React, { FC } from 'react';
import LinkButton from '../LinkButton/LinkButton';

type TSeeAllResultsButtonProps = {
  url: string;
};
export const SeeAllResultsButton: FC<TSeeAllResultsButtonProps> = ({ url }) => (
  <LinkButton
    href={url}
    variant="ghost"
    className="!text-sm !border-t2-neutral-grey03 hover:!bg-t2-neutral-grey03 !shadow-none"
  >
    See All
  </LinkButton>
);
