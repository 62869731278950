import { FC } from 'react';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';

type TTerritoryDiscoveryNoResultsProps = {
  searchTerm?: string | null;
};

export const TerritoryDiscoveryNoResults: FC<TTerritoryDiscoveryNoResultsProps> = ({ searchTerm = '' }) => (
  <NoSearchResults title={`No territories found for “${searchTerm}”`} description="Try a different search" />
);
