import { Maybe, Membership, Territory } from 't2-graphql-types';
import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/compat/router';
import { useUserTerritoryPermissions } from '../Territory/services/useUserTerritoryPermissions';
import { TerritoryLogo } from '../TerritoryHeader/TerritoryLogo';
import Typo from '../Typography';
import { TerritoryDetailsMembers } from '../TerritoryDetails/TerritoryDetailsMembers';
import { TerritoryDetailsTotalTimepoints } from '../TerritoryDetails/TerritoryDetailsTotalTimepoints';
import { VerticalSeparator } from '../VerticalSeparator/VerticalSeparator';
import { TerritoryMembershipButton } from './TerritoryMembershipButton';
import getTerritoryPageUrl from '../../services/pagesUrl/getTerritoryPageUrl';
import { TMember } from '../TerritoryMembersPopUp/MemberProfile';
import { ReadMoreContainer } from '../ReadMore/ReadMoreContainer';

type TTerritoryDetailsProps = {
  territory: Territory;
};

export const TerritoryDetails: FC<TTerritoryDetailsProps> = ({ territory }) => {
  const {
    canUpdateTerritoryGlobally,
    isTerritoryMember,
    isTerritoryAdmin,
    canUpdateTerritory,
    loading: userPermissionsLoading,
  } = useUserTerritoryPermissions({
    territoryId: territory?.id,
  });
  const router = useRouter();
  const territoryMembers = (territory?.memberships || []).map((m: Maybe<Membership>) => m?.user);
  const territoryMembersCount = territory?.membershipsCount;
  const url = getTerritoryPageUrl(territory?.slug as string);
  const onReadMoreClick = () => router?.push(url);

  return (
    <div className="relative w-full py-7 px-7 group/sidebar hover:bg-t2-shades-white bg-t2-neutral-grey02 rounded-[12px]">
      <Link href={url} className="absolute w-full h-full">
        {' '}
      </Link>
      <div className="h-full flex items-center flex-col gap-3 text-t2-neutral-grey07">
        <div className="w-[115px] h-[115px]">
          <TerritoryLogo logo={territory?.logo} />
        </div>
        <Typo variant="p2" className="!text-[22px]">
          t/{territory?.name}
        </Typo>

        <div className="w-full flex justify-center gap-5">
          <div className="flex flex-col gap-2 items-center w-1/3">
            <Typo variant="small-p" className="antialiased">
              {territory?.articlesCount || '0'}
            </Typo>
            <Typo variant="small-p" className="!text-xs text-t2-neutral-grey06 whitespace-nowrap">
              Posts
            </Typo>
          </div>
          <VerticalSeparator className="h-auto py-2 bg-t2-neutral-grey03" />
          <div className="w-1/3">
            <TerritoryDetailsTotalTimepoints
              territoryId={territory?.id}
              totalTerritoryTimePoints={Math.floor(territory?.totalTimePoints || 0)}
              placement="territoryDiscovery"
              showIcon={false}
              showTooltip={false}
            />
          </div>
        </div>
        <div className="mt-4 overflow-y-auto text-sm leading-[22px]">
          <ReadMoreContainer placement="territoryDiscovery" onClick={onReadMoreClick}>
            {territory?.description}
          </ReadMoreContainer>
        </div>
        <div className="w-full mt-3 gap-3 grow justify-self-bottom flex flex-col justify-end items-center">
          <TerritoryDetailsMembers
            territory={territory}
            territoryMembers={territoryMembers as TMember[]}
            territoryMembersCount={territoryMembersCount || 0}
            placement="territoryDiscovery"
          />
          <TerritoryMembershipButton
            canUpdateTerritoryGlobally={canUpdateTerritoryGlobally}
            canUpdateTerritory={canUpdateTerritory}
            isTerritoryMember={isTerritoryMember}
            isTerritoryAdmin={isTerritoryAdmin}
            loading={userPermissionsLoading}
            territorySlug={territory?.slug as string}
          />
        </div>
      </div>
    </div>
  );
};
